/* @tailwind base;
@tailwind components;
@tailwind utilities; */
:root {
  --primary-clr: hsl(45, 100%, 57%);
  --primary-clr-200: hsla(45, 100%, 57%, 0.8);
  --primary-clr-300: hsla(45, 100%, 57%, 0.7);
  --primary-clr-400: hsla(45, 100%, 57%, 0.6);
  --primary-clr-500: hsla(45, 100%, 57%, 0.5);
  --primary-clr-600: hsla(45, 100%, 57%, 0.4);
  --primary-clr-700: hsla(45, 100%, 57%, 0.3);
  --primary-clr-800: hsla(45, 100%, 57%, 0.2);
  --primary-clr-900: hsla(45, 100%, 57%, 0.1);
  --form-padding-top: 0.6rem;
  --form-padding-left: 1.2rem;
  --form-field-padding: var(--form-padding-top) var(--form-padding-left);
  --rounded: 2rem;
}


.item-center {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.switch {
  accent-color: red !important;
}

.ant-card{
  border-radius: 1rem !important;
}

select {
  border: unset !important;
}
/* @override ant design css */

/* Form Fields */
.ant-form-item > .ant-form-item-label {
  font-weight: 800;
}
.ant-form-item .ant-input,
.ant-select *,
.ant-picker {
  border: unset !important;
  border-radius: 2rem !important;
  background: transparent !important;
}

.ant-input:hover,
.ant-input:focus,
.ant-input:focus-within {
  box-shadow: unset !important;
}
.ant-select-selector,
.ant-select-selector:focus {
  border: unset !important;
  box-shadow: unset !important;
}

.ant-form-item.no-border .ant-form-item-control-input-content {
  border: unset !important;
}

.ant-form-item-control-input-content {
  /* border: 1px solid; */
  padding: var(--form-field-padding) !important;
  border-radius: var(--rounded) !important;
}
.ant-form-item-control-input-content:not(:has(.ant-rate)):not(
    :has(.ant-slider)
  ) {
  background: #f4f4f4;
}
.ant-form-item-control-input-content:focus,
.ant-form-item-control-input-content:focus-within {
  border: 1px solid var(--primary-clr);
}

/* Card */

.card-1 {
  position: relative;
  border-radius: 2rem;
  z-index: 2;
  overflow: hidden;
}
.card-1::before {
  content: "";
  background-color: hsl(0, 0%, 99%);
  position: absolute;
  /* inset: 0; */
  width: 50%;
  transform: translate(-60%, 50%);
  height: 100%;
  border-radius: 50%;
  z-index: -1;
}
.card-1:hover .card-1::before {
  width: 100% !important;
  cursor: pointer;
  transform: translate(0);
  background: red;
}

.card-shadow {
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
}

.table-no-border td,
.table-no-border th {
  border: unset !important;
}

.table-no-border tr td:first-child {
  font-weight: bold !important;

  padding-left: 0;
  font-size: 1rem;
}
.table-no-border tr td:not(:first-child) {
  font-size: 0.9rem;
}

/* FIle Uploader   */
/* .file-uploader {
  background: var(--primary-clr-900);
  border: 1px dashed;
  border-color: var(--primary-clr);
  padding: 1rem;
  cursor: pointer;
}
.file-uploader:hover .label-browse {
  text-decoration: underline;
}
.file-upload-output {
  list-style: none;
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}
.file-upload-output li {
  padding: 0.5rem 1rem;
  background: #f4f4f4;
  border-radius: 1rem;
  font-weight: 200;
  position: relative;
}
.file-upload-output li:hover .file-upload-output::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: red;
} */

/* Text */
.text-primary {
  color: var(--primary-clr) !important;
}
